import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Paper,
  InputBase,
  IconButton,
  CircularProgress,
} from '@material-ui/core';
import { Search } from '@material-ui/icons';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 5,
  },
}));

const CustomizedInputBase = ({
  handleClick,
  isSubmitting,
  field,
  aria,
  form: { touched, errors },
  ...rest
}) => {
  const classes = useStyles();

  return (
    <Paper className={classes.root}>
      <InputBase
        className={classes.input}
        {...field}
        {...rest}
        inputProps={{ 'aria-label': { aria } }}
      />
      <IconButton
        onClick={handleClick}
        className={classes.iconButton}
        aria-label={aria}
      >
        {isSubmitting ? <CircularProgress size={28} /> : <Search />}
      </IconButton>
      {touched[field.name] && errors[field.name] && (
        <div className="error">{errors[field.name]}</div>
      )}
    </Paper>
  );
};

CustomizedInputBase.defaultProps = {
  isSubmitting: false,
};

CustomizedInputBase.propTypes = {
  handleClick: PropTypes.func.isRequired,
  field: PropTypes.oneOfType([PropTypes.any]).isRequired,
  form: PropTypes.oneOfType([PropTypes.any]).isRequired,
  isSubmitting: PropTypes.bool,
  aria: PropTypes.string.isRequired,
};

export default CustomizedInputBase;
