import api from '../../utils/api';

const url = 'loyalties';
export default {
  getLoyaltyProgram: ({ id, ...params } = {}) =>
    api.makeEndpoint('get', `${url}/${id}?${api.serialize(params)}`)(),
  getLoyaltyByCode: (params) =>
    api.makeEndpoint('get', `${url}/code?${api.serialize(params)}`)(),
  checkIn: api.makeEndpoint('post', `${url}/check-in`),
  redeem: (payload) => api.post(`${url}/redeem`, payload),
  register: (data) => api.makeEndpoint('post', `${url}/register`)(data),
};
