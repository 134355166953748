/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
// import { getOr, isEmpty } from 'lodash/fp';

import api from './api';

const name = 'businessLead';

const submitBusiness = createAsyncThunk(
  'businessLead/submitBusiness',
  async (payload) => {
    const response = api.post(payload);

    return response;
  }
);

const { actions, reducer } = {
  ...createSlice({
    name,
    initialState: {
      isLoading: false,
      data: {},
      error: false,
    },
    extraReducers: {
      // Fetch all businesses
      [submitBusiness.pending.type]: (state) => ({
        ...state,
        isLoading: true,
        error: {},
      }),
      [submitBusiness.fulfilled]: (state, action) => {
        return {
          ...state,
          data: action.payload,
          isLoading: false,
        };
      },

      [submitBusiness.rejected.type]: (state, action) => ({
        ...state,
        isLoading: false,
        error: action.payload,
      }),
    },
  }),
};

const selectors = {};

export default {
  actions: {
    ...actions,
    submitBusiness,
  },
  selectors,
  reducer,
  name,
};
