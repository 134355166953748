/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getOr } from 'lodash/fp';
import checkCardBalance from './api';

const name = 'checkCardBalance';

const balance = createAsyncThunk(
  'checkCardBalance/balance',
  async (payload) => {
    const fn = checkCardBalance.balance(payload);
    const response = await fn();
    // await dispatch(businesses.actions.fetchBusiness(response.businessId));

    return response;
  }
);

const { actions, reducer } = {
  ...createSlice({
    name,
    initialState: {
      isLoading: false,
      error: false,
      cardDetails: {},
    },
    reducers: {
      setCardDetails: (state, { payload }) => {
        state.cardDetails = { ...state.cardDetails, ...payload };
      },
      resetCardDetails: (state) => {
        state.cardDetails = {};
      },
    },
    extraReducers: {
      // Fetch organization
      [balance.pending.type]: (state) => ({
        ...state,
        isLoading: true,
      }),
      [balance.fulfilled]: (state, action) => ({
        ...state,
        isLoading: false,
        details: action.payload.orderNumber,
      }),
      [balance.rejected.type]: (state, action) => ({
        ...state,
        isLoading: false,
        error: action.payload,
      }),
    },
  }),
};

const selectors = {
  selectIsLoading: (state) => getOr('', 'isLoading', state[name]),
  selectCardDetails: (state) => getOr({}, 'cardDetails', state[name]),
};

export default {
  actions: {
    ...actions,
    balance,
  },
  selectors,
  reducer,
  name,
};
