import api from '../../utils/api';
// TODO generate query by passed in parameters
const url = 'gift-cards';
export default {
  redeem: (cardNumber, payload) =>
    api.makeEndpoint('post', `${url}/${cardNumber}/redeem`)(payload),
  balance: (code) => api.makeEndpoint('get', `${url}/${code}`),
  gcRedeem: (payload) => api.makeEndpoint('post', `${url}/redeem`)(payload),
  giftCardsList: (id) => api.get(`${url}/list/${id}`),
};
