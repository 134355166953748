/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getOr } from 'lodash/fp';
import tickets from './api';

const name = 'tickets';

const fetchTicket = createAsyncThunk('tickets/fetchTicket', async (payload) => {
  const response = await tickets.fetchTicketEvent(payload);
  return response;
});

const getListEvents = createAsyncThunk(
  'tickets/publish-events/',
  async (payload) => {
    const response = await tickets.getListEvents(payload);
    return response;
  }
);
const checkIn = createAsyncThunk('tickets/checkIn/', async (payload) => {
  const response = await tickets.checkIn(payload);
  return response;
});
const getEventDetail = createAsyncThunk('tickets/events/', async (payload) => {
  const response = await tickets.getEventDetail(payload);
  return response;
});
const resetTicket = createAsyncThunk('memberships/resetTicket', async () => {
  return true;
});
const reservedOrder = createAsyncThunk(
  'tickets/reserved-order',
  async (orderId) => {
    const response = await tickets.reservedOrder(orderId);
    return response;
  }
);
const validateTicketCoupon = createAsyncThunk(
  'tickets/validate-ticket-coupon',
  async (payload) => {
    const response = await tickets.validateTicketCoupon(payload);
    return response;
  }
);

const calculateTicketCost = createAsyncThunk(
  'tickets/calculate',
  async (payload) => {
    const response = await tickets.calculateTicketCost(payload);
    return response;
  }
);

const { actions, reducer } = {
  ...createSlice({
    name,
    initialState: {
      isLoading: false,
      error: false,
      ticketConfig: {},
      formData: {},
      ticketEvents: [],
      eventDetail: {},
      checkInDetail: {},
    },
    reducers: {
      setFormData: (state, { payload }) => {
        state.formData = { ...state.formData, ...payload };
      },
      resetFormData: (state) => {
        state.formData = {};
      },
    },
    extraReducers: {
      // Fetch organization

      [fetchTicket.pending.type]: (state) => ({
        ...state,
        isLoading: true,
      }),
      [fetchTicket.fulfilled]: (state, action) => ({
        ...state,
        isLoading: false,
        ticketConfig: action.payload,
      }),
      [fetchTicket.rejected.type]: (state, action) => ({
        ...state,
        isLoading: false,
        error: action.payload,
      }),

      [getListEvents.pending.type]: (state) => ({
        ...state,
        isLoading: true,
      }),
      [getListEvents.fulfilled]: (state, action) => ({
        ...state,
        isLoading: false,
        ticketEvents: action.payload,
      }),
      [getEventDetail.pending.type]: (state) => ({
        ...state,
        isLoading: true,
      }),
      [getEventDetail.fulfilled]: (state, action) => ({
        ...state,
        isLoading: false,
        eventDetail: action.payload,
      }),
      [checkIn.pending.type]: (state) => ({
        ...state,
        isLoading: true,
      }),
      [checkIn.fulfilled]: (state, action) => ({
        ...state,
        isLoading: false,
        checkInDetail: action.payload,
      }),
      [checkIn.rejected]: (state) => ({
        ...state,
        isLoading: false,
        checkInDetail: { error: true },
      }),
      [resetTicket.fulfilled]: (state) => ({
        ...state,
        isLoading: false,
        checkInDetail: {},
      }),
    },
  }),
};

const selectors = {
  selectIsLoading: (state) => getOr('', 'isLoading', state[name]),
  selectTicketEvent: (state) => getOr('', 'ticketConfig', state[name]),
  selectFormData: (state) => getOr('', 'formData', state[name]),
  selectTicketEvents: (state) => getOr('', 'ticketEvents', state[name]),
  selectTicketConfig: (state) => getOr('', 'ticketConfig', state[name]),
  selectEventDetail: (state) => getOr('', 'eventDetail', state[name]),
  selectCheckInDetail: (state) => getOr('', 'checkInDetail', state[name]),
};

export default {
  actions: {
    ...actions,
    fetchTicket,
    getListEvents,
    getEventDetail,
    checkIn,
    resetTicket,
    reservedOrder,
    validateTicketCoupon,
    calculateTicketCost,
  },
  selectors,
  reducer,
  name,
};
