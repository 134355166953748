import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getOr } from 'lodash/fp';
import loyaltyApi from './api';

const name = 'loyalties';

const getLoyaltyProgram = createAsyncThunk(
  'loyalties/getLoyaltyProgram',
  async (params) => {
    return loyaltyApi.getLoyaltyProgram(params);
  }
);
const getLoyaltyDetail = createAsyncThunk(
  'loyalties/getLoyaltyByCode',
  async (params, { rejectWithValue }) => {
    try {
      return await loyaltyApi.getLoyaltyByCode(params);
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);
const redeem = createAsyncThunk(
  'loyalties/redeem',
  async (params, { rejectWithValue }) => {
    try {
      return await loyaltyApi.redeem(params);
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const checkIn = createAsyncThunk('loyalties/checkIn', async (payload) => {
  const res = await loyaltyApi.checkIn(payload);
  return res;
});

const register = createAsyncThunk(
  'loyalties/register',
  async (data, { rejectWithValue }) => {
    try {
      return await loyaltyApi.register({ data });
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);
const resetLoyalty = createAsyncThunk('loyalties/resetLoyalty', async () => {
  return true;
});

const { actions, reducer } = {
  ...createSlice({
    name,
    initialState: {
      isLoading: false,
      program: {},
      loyalty: {},
    },
    reducers: {},
    extraReducers: {
      [getLoyaltyProgram.pending.type]: (state) => ({
        ...state,
        isLoading: true,
      }),
      [getLoyaltyProgram.fulfilled]: (state, action) => ({
        ...state,
        isLoading: false,
        program: action.payload,
      }),
      [getLoyaltyProgram.rejected.type]: (state, action) => ({
        ...state,
        isLoading: false,
        error: action.payload,
      }),
      [resetLoyalty.fulfilled]: (state) => ({
        ...state,
        isLoading: false,
        loyalty: {},
      }),
    },
  }),
};

const selectors = {
  selectIsLoading: (state) => getOr(false, 'isLoading', state[name]),
  selectProgram: (state) => getOr({}, 'program', state[name]),
  selectDetails: (state) => getOr({}, 'loyalty', state[name]),
};

export default {
  actions: {
    ...actions,
    getLoyaltyProgram,
    getLoyaltyDetail,
    redeem,
    checkIn,
    resetLoyalty,
    register,
  },
  selectors,
  reducer,
  name,
};
