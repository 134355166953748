/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getOr } from 'lodash/fp';
import giftCards from './api';

const name = 'giftCards';

const redeem = createAsyncThunk('giftCards/redeem', async (payload) => {
  const { cardNumber, data } = payload;
  const response = await giftCards.redeem(cardNumber, { data });

  return response;
});

const gcRedeem = createAsyncThunk(
  'giftCards/gcRedeem',
  async (data, { rejectWithValue }) => {
    try {
      return await giftCards.gcRedeem({ data });
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const balance = createAsyncThunk('giftCards/balance', async (cardNumber) => {
  const fn = giftCards.balance(cardNumber);
  const response = await fn();
  return response;
});

const giftCardsList = createAsyncThunk(
  'giftCards/giftCardsList',
  async (cardNumber) => {
    return giftCards.giftCardsList(cardNumber);
  }
);

const { actions, reducer } = {
  ...createSlice({
    name,
    initialState: {
      isLoading: false,
      error: false,
      details: {},
      requestOtp: false,
      otp: false,
      list: {
        total: 0,
        data: [],
      },
    },
    reducers: {
      setFormData: (state, { payload }) => {
        state.formData = { ...state.formData, ...payload };
      },
      resetFormData: (state) => {
        state.formData = {};
      },
    },
    extraReducers: {
      // Fetch organization

      [redeem.pending.type]: (state) => ({
        ...state,
        isLoading: true,
      }),
      [redeem.fulfilled]: (state, action) => ({
        ...state,
        isLoading: false,
        details: action.payload,
      }),
      [redeem.rejected.type]: (state, action) => ({
        ...state,
        isLoading: false,
        error: action.payload,
      }),
      [gcRedeem.pending.type]: (state) => ({
        ...state,
        isLoading: true,
      }),
      [gcRedeem.fulfilled]: (state, action) => ({
        ...state,
        isLoading: false,
        details: action.payload.giftCard,
        otp: action.payload.otp ? true : state.otp,
      }),
      [gcRedeem.rejected.type]: (state, action) => ({
        ...state,
        isLoading: false,
        error: action.payload,
      }),
      [balance.pending.type]: (state) => ({
        ...state,
        isLoading: true,
      }),
      [balance.fulfilled]: (state, action) => ({
        ...state,
        isLoading: false,
        details: action.payload.orderNumber,
      }),
      [balance.rejected.type]: (state, action) => ({
        ...state,
        isLoading: false,
        error: action.payload,
      }),

      [giftCardsList.pending.type]: (state) => ({
        ...state,
        isLoading: true,
      }),
      [giftCardsList.fulfilled]: (state, action) => {
        console.log(action);
        return {
          ...state,
          isLoading: false,
          list: action.payload,
        };
      },
      [giftCardsList.rejected.type]: (state, action) => ({
        ...state,
        isLoading: false,
        error: action.payload,
      }),
    },
  }),
};

const selectors = {
  selectIsLoading: (state) => getOr('', 'isLoading', state[name]),
  selectGiftCards: (state) => getOr('', 'list', state[name]),
  selectDetails: (state) => getOr('', 'details', state[name]),
  selectOtp: (state) => getOr('', 'otp', state[name]),
};

export default {
  actions: {
    ...actions,
    redeem,
    balance,
    gcRedeem,
    giftCardsList,
  },
  selectors,
  reducer,
  name,
};
