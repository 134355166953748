import api from '../../utils/api';

const path = 'catalog/businesses';
// TODO generate query by passed in parameters
export default {
  fetch: (query, id) =>
    api.makeEndpoint('get', `catalog/${id ? `${id}` : `businesses${query}`}`),
  fetchBusinesses: (limit, defaultType) =>
    api.makeEndpoint('get', `${path}/?limit=${limit}&type=${defaultType}`),
  fetchBusiness: (id) => api.makeEndpoint('get', `catalog/${id}`),
  fetchMerchant: (id) => api.makeEndpoint('get', `merchants/${id}`),
  fetchMore: (fetchMoreQty, typeId, categoryId, lastBusinessId, order) =>
    api.makeEndpoint(
      'get',
      `${path}/?limit=${fetchMoreQty}&type=${typeId}&category=${categoryId}&after=${lastBusinessId}&order=${order}`
    ),
  searchByName: (businessName) =>
    api.makeEndpoint('get', `${path}/?name=${businessName}`),
  filterByCategory: (limit, typeId, categoryId) =>
    api.makeEndpoint(
      'get',
      `${path}/?limit=${limit}&type=${typeId}&category=${categoryId}`
    ),
  filterByType: (limit, typeId) =>
    api.makeEndpoint('get', `${path}?limit=${limit}&type=${typeId}`),
  orderBy: (limit, typeId, categoryId, order) =>
    api.makeEndpoint(
      'get',
      `${path}?limit=${limit}&type=${typeId}&category=${categoryId}&order=${order}`
    ),
};
