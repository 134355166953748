/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getOr } from 'lodash/fp';
import api from './api';

const name = 'organizations';

const fetchOrganizations = createAsyncThunk(
  'organizations/fetchOrganizations',
  async () => {
    const fn = api.fetch('');
    const response = await fn();
    return response;
  }
);

const fetchOrganization = createAsyncThunk(
  'fetchOrganization',
  async (orgId) => {
    const fn = api.fetch(orgId);
    const response = await fn();
    return response;
  }
);

const { actions, reducer } = {
  ...createSlice({
    name,
    initialState: {
      isLoading: false,
      error: false,
      data: [],
      organization: {},
    },
    extraReducers: {
      // Fetch all organizations

      [fetchOrganizations.pending.type]: (state) => ({
        ...state,
        isLoading: true,
      }),
      [fetchOrganizations.fulfilled]: (state, action) => ({
        ...state,
        isLoading: false,
        data: action.payload,
      }),
      [fetchOrganizations.rejected.type]: (state, action) => ({
        ...state,
        isLoading: false,
        error: action.payload,
      }),

      // Fetch organization

      [fetchOrganization.pending.type]: (state) => ({
        ...state,
        isLoading: true,
      }),
      [fetchOrganization.fulfilled]: (state, action) => ({
        ...state,
        isLoading: false,
        organization: action.payload,
      }),
      [fetchOrganization.rejected.type]: (state, action) => ({
        ...state,
        isLoading: false,
        error: action.payload,
      }),
    },
  }),
};

const selectors = {
  selectIsLoading: (state) => getOr('', 'isLoading', state[name]),
  selectOrganization: (state) => getOr([], 'organization', state[name]),
  selectOrganizations: (state) => getOr([], 'data', state[name]),
};

export default {
  actions: {
    ...actions,
    fetchOrganization,
    fetchOrganizations,
  },
  selectors,
  reducer,
  name,
};
