/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getOr } from 'lodash/fp';
import api from './api';

const name = 'categories';

const fetchCategories = createAsyncThunk(
  'categories/fetchCategories',
  async (typeId) => {
    const fn = api.fetch(`?type=${typeId}`);
    const response = await fn();

    return response;
  }
);

const defaultData = [{ id: '1' }, { id: '2' }, { id: '3' }];

const { actions, reducer } = {
  ...createSlice({
    name,
    initialState: {
      isLoading: false,
      error: false,
      selected: '',
      data: [...defaultData],
    },
    reducers: {
      setSelectedCategoryFilter: (state, { payload }) => {
        state.selected = payload;
      },
    },
    extraReducers: {
      // Fetch category filters

      [fetchCategories.pending.type]: (state) => ({
        ...state,
        data: [...defaultData],
        isLoading: true,
      }),
      [fetchCategories.fulfilled]: (state, action) => ({
        ...state,
        isLoading: false,
        data: action.payload,
      }),
      [fetchCategories.rejected.type]: (state, action) => ({
        ...state,
        isLoading: false,
        error: action.payload,
      }),
    },
  }),
};

const selectors = {
  selectIsLoading: (state) => getOr(false, 'isLoading', state[name]),
  selectCategories: (state) => getOr([], 'data', state[name]),
  selectSelectedCategory: (state) => getOr('', 'selected', state[name]),
};

export default {
  actions: {
    ...actions,
    fetchCategories,
  },
  selectors,
  reducer,
  name,
};
