import { combineReducers } from '@reduxjs/toolkit';
import businesses from './businesses';
import organizations from './organizations';
import types from './types';
import categories from './categories';
import checkout from './checkout';
import businessLead from './businessLead';
import alert from './alert';
import giftCards from './giftCards';
import checkCardBalance from './checkCardBalance';
import memberships from './memberships';
import loyalties from './loyalties';
import tickets from './tickets';

const rootReducer = combineReducers({
  businesses: businesses.reducer,
  businessLead: businessLead.reducer,
  organizations: organizations.reducer,
  types: types.reducer,
  categories: categories.reducer,
  checkout: checkout.reducer,
  alert: alert.reducer,
  giftCards: giftCards.reducer,
  memberships: memberships.reducer,
  checkCardBalance: checkCardBalance.reducer,
  loyalties: loyalties.reducer,
  tickets: tickets.reducer,
});

export default rootReducer;
