import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getOr } from 'lodash/fp';
import membershipApi from './api';

const name = 'memberships';

const getMembershipConfig = createAsyncThunk(
  'memberships/getMembershipConfig',
  async (params) => {
    return membershipApi.getMembershipConfig(params);
  }
);

const checkIn = createAsyncThunk('memberships/checkIn', async (payload) => {
  const res = await membershipApi.checkIn(payload);
  return res;
});

const register = createAsyncThunk(
  'memberships/register',
  async (payload, { rejectWithValue }) => {
    try {
      return await membershipApi.register({ data: payload });
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);
const createSubscription = createAsyncThunk(
  'memberships/createSubscription',
  async (payload, { rejectWithValue }) => {
    try {
      return await membershipApi.createSubscription({ data: payload });
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);
const cancelRequest = createAsyncThunk(
  'memberships/cancelRequest',
  async (payload, { rejectWithValue }) => {
    try {
      return await membershipApi.cancelRequest(payload);
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);
const cancelMembership = createAsyncThunk(
  'memberships/cancelMembership',
  async (payload, { rejectWithValue }) => {
    try {
      return await membershipApi.cancelMembership(payload);
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const validateMembershipCode = createAsyncThunk(
  'memberships/validateMembershipCode',
  async (payload, { rejectWithValue }) => {
    try {
      return await membershipApi.validateMembershipCode({ data: payload });
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);
const resetMembership = createAsyncThunk(
  'memberships/resetMembership',
  async () => {
    return true;
  }
);
const updateMembershipCardOnFile = createAsyncThunk(
  'memberships/updateMembershipCardOnFile',
  async (payload, { rejectWithValue }) => {
    try {
      return await membershipApi.updateMembershipCardOnFile({ data: payload });
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);
const getMembershipFreeItemConfig = createAsyncThunk(
  'memberships/getMembershipFreeItemConfig',
  async (params) => {
    return membershipApi.getMembershipFreeItemConfig(params);
  }
);

const getMembershipByCode = createAsyncThunk(
  'memberships/getMembershipByCode',
  async (payload, { rejectWithValue }) => {
    try {
      return await membershipApi.getMembershipByCode(payload);
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const getMembershipFreeItem = createAsyncThunk(
  'memberships/getMembershipFreeItem',
  async (params) => {
    return membershipApi.getMembershipFreeItem(params);
  }
);

const createMembershipFreeItem = createAsyncThunk(
  'membership/createMembershipFreeItem',
  async (payload, { rejectWithValue }) => {
    try {
      return await membershipApi.createMembershipFreeItem({ data: payload });
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const { actions, reducer } = {
  ...createSlice({
    name,
    initialState: {
      isLoading: false,
      configs: {},
      membership: {},
      freeItemConfigs: [],
      freeItems: [],
    },
    reducers: {},
    extraReducers: {
      [getMembershipConfig.pending.type]: (state) => ({
        ...state,
        isLoading: true,
      }),
      [getMembershipConfig.fulfilled]: (state, action) => ({
        ...state,
        isLoading: false,
        configs: action.payload,
      }),
      [getMembershipConfig.rejected.type]: (state, action) => ({
        ...state,
        isLoading: false,
        error: action.payload,
      }),
      [checkIn.pending.type]: (state) => ({
        ...state,
        isLoading: true,
      }),
      [checkIn.fulfilled]: (state, action) => ({
        ...state,
        isLoading: false,
        membership: action.payload,
      }),
      [checkIn.rejected]: (state) => ({
        ...state,
        isLoading: false,
        membership: { error: true },
      }),
      [checkIn.pending.type]: (state) => ({
        ...state,
        isLoading: true,
      }),
      [checkIn.fulfilled]: (state, action) => ({
        ...state,
        isLoading: false,
        membership: action.payload,
      }),
      [checkIn.rejected]: (state) => ({
        ...state,
        isLoading: false,
        membership: { error: true },
      }),
      [getMembershipByCode.pending.type]: (state) => ({
        ...state,
        isLoading: true,
      }),
      [getMembershipByCode.fulfilled]: (state, action) => ({
        ...state,
        isLoading: false,
        membership: action.payload,
      }),
      [getMembershipByCode.rejected]: (state) => ({
        ...state,
        isLoading: false,
        membership: { error: true },
      }),
      [resetMembership.fulfilled]: (state) => ({
        ...state,
        isLoading: false,
        membership: {},
      }),
      [getMembershipFreeItemConfig.pending.type]: (state) => ({
        ...state,
        isLoading: true,
      }),
      [getMembershipFreeItemConfig.fulfilled]: (state, action) => ({
        ...state,
        isLoading: false,
        freeItemConfigs: action.payload,
      }),
      [getMembershipFreeItemConfig.rejected.type]: (state, action) => ({
        ...state,
        isLoading: false,
        error: action.payload,
      }),
      [getMembershipFreeItem.pending.type]: (state) => ({
        ...state,
        isLoading: true,
      }),
      [getMembershipFreeItem.fulfilled]: (state, action) => ({
        ...state,
        isLoading: false,
        freeItems: action.payload,
      }),
      [getMembershipFreeItem.rejected.type]: (state, action) => ({
        ...state,
        isLoading: false,
        error: action.payload,
      }),
      [createMembershipFreeItem.pending]: (state) => ({
        ...state,
        isLoading: true,
      }),
      [createMembershipFreeItem.fulfilled]: (state) => ({
        ...state,
        isLoading: false,
      }),
      [createMembershipFreeItem.rejected]: (state, payload) => ({
        ...state,
        isLoading: false,
        error: payload,
      }),
    },
  }),
};

const selectors = {
  selectIsLoading: (state) => getOr(false, 'isLoading', state[name]),
  selectConfigs: (state) => getOr({}, 'configs', state[name]),
  selectFreeItemConfig: (state) => getOr({}, 'freeItemConfigs', state[name]),
  selectFreeItems: (state) => getOr({}, 'freeItems', state[name]),
  selectDetails: (state) => getOr({}, 'membership', state[name]),
};

export default {
  actions: {
    ...actions,
    getMembershipConfig,
    validateMembershipCode,
    checkIn,
    resetMembership,
    register,
    createSubscription,
    cancelRequest,
    cancelMembership,
    updateMembershipCardOnFile,
    getMembershipFreeItemConfig,
    getMembershipFreeItem,
    createMembershipFreeItem,
    getMembershipByCode,
  },
  selectors,
  reducer,
  name,
};
