/* eslint-disable no-restricted-syntax,no-prototype-builtins */
import api from '../../utils/api';

const url = 'memberships';
const serialize = (obj) => {
  const str = [];
  for (const p in obj)
    if (obj.hasOwnProperty(p)) {
      str.push(`${encodeURIComponent(p)}=${encodeURIComponent(obj[p])}`);
    }
  return str.join('&');
};
export default {
  getMembershipConfig: ({ id, ...params } = {}) =>
    api.makeEndpoint('get', `${url}/${id}?${serialize(params)}`)(),
  checkIn: api.makeEndpoint('post', `${url}/check-in`),
  register: api.makeEndpoint('post', `${url}/register`),
  createSubscription: api.makeEndpoint('post', `${url}/create-subscription`),
  cancelRequest: api.makeEndpoint('post', `${url}/cancel/request`),
  cancelMembership: (payload) => api.post(`${url}/cancel/confirm`, payload),
  getMembershipById: (id) => api.makeEndpoint('get', `${url}/${id}/info`),
  validateMembershipCode: api.makeEndpoint(
    'post',
    `${url}/validate-membership-code`
  ),
  updateMembershipCardOnFile: api.makeEndpoint('post', `${url}/update-ccof`),
  getMembershipFreeItemConfig: (params) =>
    api.makeEndpoint('get', `${url}/${params}/free-item-configs`)(),
  getMembershipFreeItem: (memberId) =>
    api.makeEndpoint('get', `${url}/${memberId}/free-items`)(),
  createMembershipFreeItem: (payload) =>
    api.makeEndpoint('post', `${url}/create-free-item`)(payload),
  getMembershipByCode: (code) => api.get(`${url}/get-by-code/?code=${code}`),
};
