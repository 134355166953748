/* eslint-disable import/prefer-default-export */
/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import wrapWithProvider from './withProvider';
import './src/assets/fonts.css';

export const wrapRootElement = wrapWithProvider;
