import React from 'react';
import { Provider } from 'react-redux';
// eslint-disable-next-line import/no-extraneous-dependencies
import ThemeTopLayout from 'gatsby-theme-material-ui-top-layout/src/components/top-layout';
// import { ThemeProvider } from '@material-ui/styles';
// import LocalizationProvider from '@mui/lab/LocalizationProvider';
// import { LocalizationProvider } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { PersistGate } from 'redux-persist/integration/react';
import { setStore } from './src/utils/api';

import theme from './src/gatsby-theme-material-ui-top-layout/theme';
import { AlertBar } from './src/components/custom';
import createStore from './src/state/createStore';

// eslint-disable-next-line react/display-name,react/prop-types
export default ({ element }) => {
  // Instantiating store in `wrapRootElement` handler ensures:
  //  - there is fresh store for each SSR page
  //  - it will be called only once in browser, when React mounts
  const { store, persistor } = createStore();
  setStore(store);

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ThemeTopLayout theme={theme}>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <>
              <AlertBar />
              {element}
            </>
          </LocalizationProvider>
        </ThemeTopLayout>
      </PersistGate>
    </Provider>
  );
};
