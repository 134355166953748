import api from '../../utils/api';
// TODO generate query by passed in parameters

export default {
  stripe: api.makeEndpoint('post', `checkout/secret`),
  clover: api.makeEndpoint('post', `checkout/clover`),
  square: api.makeEndpoint('post', `checkout/square`),
  getCloverIframeToken: (id) =>
    api.makeEndpoint('get', `checkout/clover-token?businessId=${id}`),
  totals: ({ id, businessId, giftCardConfigId, amount }) =>
    api.makeEndpoint(
      'get',
      `checkout/totalamount?id=${
        giftCardConfigId || id || businessId
      }&amount=${amount}`
    ),
  getTicketTotals: (payload) =>
    api.get(`tickets/ticket-total-amount?${api.serialize(payload)}`),
  ticketCheckout: (payload) => api.post(`tickets/checkout`, payload),
};
