/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getOr, isEmpty } from 'lodash/fp';

import api from './api';

const name = 'businesses';

const fetchMoreQty = 10;
const limit = 19;

// TODO move axios to API folder
const fetchBusinesses = createAsyncThunk(
  'businesses/fetchAllBusinesses',
  async (defaultType) => {
    const fn = api.fetchBusinesses(limit, defaultType);
    const response = await fn();

    return response;
  }
);

const updateBusiness = createAsyncThunk(
  'businesses/updateBusiness',
  async (payload) => {
    return payload;
  }
);

const fetchBusiness = createAsyncThunk(
  'businesses/fetchBusiness',
  async (businessId) => {
    const fn = api.fetchBusiness(businessId);
    const response = await fn();

    return response;
  }
);

const fetchMerchant = createAsyncThunk(
  'businesses/fetchMerchant',
  async (businessId) => {
    const fn = api.fetchMerchant(businessId);
    const response = await fn();

    return response;
  }
);

const fetchMore = createAsyncThunk(
  'businesses/fetchMore',
  async (
    { lastBusinessId, selectedType: typeId, selectedCategory: categoryId },
    { getState }
  ) => {
    const { order } = getState().businesses;
    const fn = api.fetchMore(
      fetchMoreQty,
      typeId,
      categoryId,
      lastBusinessId,
      order
    );
    const response = await fn();

    return response;
  }
);

const searchByName = createAsyncThunk(
  'businesses/searchByName',
  async ({ search: businessName }) => {
    const fn = api.searchByName(businessName);
    const response = await fn();

    return response;
  }
);

const filterByCategory = createAsyncThunk(
  'businesses/filterByCategory',
  async ({ typeId, categoryId }) => {
    const fn = api.filterByCategory(limit, typeId, categoryId);
    const response = await fn();

    return response;
  }
);

const filterByType = createAsyncThunk(
  'businesses/filterByType',
  async (typeId) => {
    const fn = api.filterByType(limit, typeId);
    const response = await fn();

    return response;
  }
);
const orderBy = createAsyncThunk('businesses/orderBy', async (payload) => {
  const { typeId, categoryId, order } = payload;
  const fn = api.orderBy(limit, typeId, categoryId, order);
  const response = await fn();
  return response;
});

const preloadData = [{ id: '1' }];

const { actions, reducer } = {
  ...createSlice({
    name,
    initialState: {
      isLoading: false,
      isLoadingMore: false,
      isAll: false,
      error: false,
      data: [...preloadData],
      order: '',
      business: {},
    },
    extraReducers: {
      // Fetch all businesses
      [fetchBusinesses.pending.type]: (state) => ({
        ...state,
        data: [...preloadData],
        isLoading: true,
        error: {},
        order: '',
      }),
      [fetchBusinesses.fulfilled]: (state, action) => {
        const last = getOr([], 'payload', action)[limit - 1];
        const displayData = getOr([], 'payload', action).slice(0, limit - 1);

        return {
          ...state,
          isLoading: false,
          data: displayData,
          isAll: !isEmpty(last),
        };
      },
      [fetchBusinesses.rejected.type]: (state, action) => ({
        ...state,
        isLoading: false,
        error: action.payload,
      }),

      // Fetch/Load more

      [fetchMore.pending.type]: (state) => ({
        ...state,
        isLoadingMore: true,
        error: {},
      }),
      [fetchMore.fulfilled]: (state, action) => {
        const last = getOr([], 'payload', action)[fetchMoreQty - 1];
        const displayData = getOr([], 'payload', action).slice(
          0,
          fetchMoreQty - 1
        );
        return {
          ...state,
          isLoadingMore: false,
          data: [...state.data, ...displayData],
          isAll: !isEmpty(last),
        };
      },
      [fetchMore.rejected.type]: (state, action) => ({
        ...state,
        isLoadingMore: false,
        error: action.payload,
      }),

      // Search by name

      [searchByName.pending.type]: (state) => ({
        ...state,
        data: [...preloadData],
        isLoading: true,
        order: '',
      }),
      [searchByName.fulfilled]: (state, action) => ({
        ...state,
        isLoading: false,
        data: getOr([], 'payload', action),
        isAll: false,
      }),
      [searchByName.rejected.type]: (state, action) => ({
        ...state,
        isLoading: false,
        error: action.payload,
      }),

      // Filter by category

      [filterByCategory.pending.type]: (state) => ({
        ...state,
        data: [...preloadData],
        isLoading: true,
        order: '',
      }),
      [filterByCategory.fulfilled]: (state, action) => {
        const last = getOr([], 'payload', action)[limit - 1];
        const displayData = getOr([], 'payload', action).slice(0, limit - 1);

        return {
          ...state,
          isLoading: false,
          data: displayData,
          isAll: !isEmpty(last),
        };
      },
      [filterByCategory.rejected.type]: (state, action) => ({
        ...state,
        isLoading: false,
        error: action.payload,
      }),

      // Filter by type

      [filterByType.pending.type]: (state) => ({
        ...state,
        data: [...preloadData],
        isLoading: true,
        order: '',
      }),
      [filterByType.fulfilled]: (state, action) => {
        const last = getOr([], 'payload', action)[limit - 1];
        const displayData = getOr([], 'payload', action).slice(0, limit - 1);

        return {
          ...state,
          isLoading: false,
          data: displayData,
          isAll: !isEmpty(last),
        };
      },
      [filterByType.rejected.type]: (state, action) => ({
        ...state,
        isLoading: false,
        error: action.payload,
      }),

      // Fetch business

      [fetchBusiness.pending.type]: (state) => ({
        ...state,
        data: [...preloadData],
        isLoading: true,
      }),
      [fetchBusiness.fulfilled]: (state, action) => ({
        ...state,
        isLoading: false,
        business: getOr([], 'payload', action),
      }),
      [updateBusiness.fulfilled]: (state, action) => ({
        ...state,
        isLoading: false,
        business: { ...state.business, ...getOr({}, 'payload', action) },
      }),
      [fetchBusiness.rejected.type]: (state, action) => ({
        ...state,
        isLoading: false,
        error: action.payload,
      }),
      [fetchMerchant.pending.type]: (state) => ({
        ...state,
        data: [...preloadData],
        isLoading: true,
      }),
      [fetchMerchant.fulfilled]: (state, action) => ({
        ...state,
        isLoading: false,
        business: getOr([], 'payload', action),
      }),
      [fetchMerchant.rejected.type]: (state, action) => ({
        ...state,
        isLoading: false,
        error: action.payload,
      }),

      // Order by
      [orderBy.pending.type]: (state) => ({
        ...state,
        data: [...preloadData],
        isLoading: true,
      }),
      [orderBy.fulfilled]: (state, action) => {
        const last = getOr([], 'payload', action)[limit - 1];
        const displayData = getOr([], 'payload', action).slice(0, limit - 1);
        const order = getOr('', ['meta', 'arg', 'order'], action);
        return {
          ...state,
          isLoading: false,
          data: displayData,
          order,
          isAll: !isEmpty(last),
        };
      },
      [orderBy.rejected.type]: (state, action) => ({
        ...state,
        isLoading: false,
        order: '',
        error: action.payload,
      }),
    },
  }),
};

const selectors = {
  // Businesses
  selectAllBusinesses: (state) => getOr([], 'data', state[name]),
  selectBusiness: (state) => getOr([], 'business', state[name]),
  selectIsLoading: (state) => getOr(false, 'isLoading', state[name]),
  selectLastBusinessId: (state) =>
    getOr('', 'id', state[name].data[state[name].data.length - 1]),
  selectIsAll: (state) => getOr([], 'isAll', state[name]),
  selectisLoadingMore: (state) => getOr([], 'isLoadingMore', state[name]),
};

export default {
  actions: {
    ...actions,
    fetchBusinesses,
    updateBusiness,
    fetchMore,
    searchByName,
    filterByType,
    filterByCategory,
    fetchBusiness,
    fetchMerchant,
    orderBy,
  },
  selectors,
  reducer,
  name,
};
