/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getOr } from 'lodash/fp';
import api from './api';

const name = 'types';

const fetchTypes = createAsyncThunk('types/fetchTypes', async () => {
  const fn = api.fetch();
  const response = await fn();

  return response;
});

const defaultData = [{ id: '1' }, { id: '2' }];

const { actions, reducer } = {
  ...createSlice({
    name,
    initialState: {
      isLoading: false,
      error: {},
      selected: '',
      data: [...defaultData],
    },
    reducers: {
      setSelectedTypeFilter: (state, { payload }) => {
        state.selected = payload;
      },
    },
    extraReducers: {
      // Fetch type filters

      [fetchTypes.pending.type]: (state) => ({
        ...state,
        isLoading: true,
      }),
      [fetchTypes.fulfilled]: (state, action) => ({
        ...state,
        isLoading: false,
        data: action.payload,
      }),
      [fetchTypes.rejected.type]: (state, action) => ({
        ...state,
        isLoading: false,
        error: action.payload,
      }),
    },
  }),
};

const selectors = {
  selectIsLoading: (state) => getOr(false, 'isLoading', state[name]),
  selectFilterTypes: (state) => getOr([], 'data', state[name]),
  selectSelectedType: (state) => getOr('', 'selected', state[name]),
};

export default {
  actions: {
    ...actions,
    fetchTypes,
  },
  selectors,
  reducer,
  name,
};
