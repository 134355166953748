/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getOr } from 'lodash/fp';
import api from './api';

const name = 'checkout';

const getSecret = createAsyncThunk('checkout/secret', async (payload) => {
  const response = await api.stripe({ data: payload });

  return response;
});

const getCloverIframeToken = createAsyncThunk(
  'checkout/getCloverIframeToken',
  async (id) => {
    const fn = await api.getCloverIframeToken(id);
    const response = await fn();

    return response;
  }
);

const squareCheckout = createAsyncThunk(
  'checkout/square',
  async (data, { rejectWithValue }) => {
    try {
      return await api.square({ data });
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const cloverCheckout = createAsyncThunk(
  'checkout/clover',
  async (data, { rejectWithValue }) => {
    try {
      return await api.clover({ data });
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const getTotals = createAsyncThunk('checkout/getTotals', async (payload) => {
  const fn = api.totals(payload);
  const response = await fn();

  return response;
});

const getTicketTotals = createAsyncThunk(
  'checkout/getTicketTotals',
  async (data, { rejectWithValue }) => {
    try {
      return await api.getTicketTotals(data);
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);
const ticketCheckout = createAsyncThunk(
  'checkout/ticketCheckout',
  async (payload) => {
    return api.ticketCheckout(payload);
  }
);

const { actions, reducer } = {
  ...createSlice({
    name,
    initialState: {
      isLoading: false,
      error: false,
      formData: {},
      secret: '',
      orderNumber: '195317-31793D3C',
    },
    reducers: {
      setFormData: (state, { payload }) => {
        state.formData = { ...state.formData, ...payload };
      },
      resetFormData: (state) => {
        state.formData = {};
      },
    },
    extraReducers: {
      // Fetch organization

      [getSecret.pending.type]: (state) => ({
        ...state,
        isLoading: true,
      }),
      [getSecret.fulfilled]: (state, action) => ({
        ...state,
        isLoading: false,
        secret: action.payload.secret,
        orderNumber: action.payload.orderNumber,
      }),
      [getSecret.rejected.type]: (state, action) => ({
        ...state,
        isLoading: false,
        error: action.payload,
      }),
      [squareCheckout.pending.type]: (state) => ({
        ...state,
        isLoading: true,
      }),
      [squareCheckout.fulfilled]: (state, action) => ({
        ...state,
        isLoading: false,
        orderNumber: action.payload.orderNumber,
      }),
      [squareCheckout.rejected.type]: (state, action) => ({
        ...state,
        isLoading: false,
        error: action.payload,
      }),
      [cloverCheckout.pending.type]: (state) => ({
        ...state,
        isLoading: true,
      }),
      [cloverCheckout.fulfilled]: (state, action) => ({
        ...state,
        isLoading: false,
        orderNumber: action.payload.orderNumber,
      }),
      [cloverCheckout.rejected.type]: (state, action) => ({
        ...state,
        isLoading: false,
        error: action.payload,
      }),
      [ticketCheckout.pending.type]: (state) => ({
        ...state,
        isLoading: true,
      }),
      [ticketCheckout.fulfilled]: (state, action) => ({
        ...state,
        isLoading: false,
        orderNumber: action.payload.orderNumber,
      }),
      [ticketCheckout.rejected.type]: (state, action) => ({
        ...state,
        isLoading: false,
        error: action.payload,
      }),
    },
  }),
};

const selectors = {
  selectIsLoading: (state) => getOr('', 'isLoading', state[name]),
  selectSecret: (state) => getOr('', 'secret', state[name]),
  selectOrderNumber: (state) => getOr('', 'orderNumber', state[name]),
  selectFormData: (state) =>
    getOr({ currency: 'USD' }, 'formData', state[name]),
};

export default {
  actions: {
    ...actions,
    getSecret,
    squareCheckout,
    getTotals,
    getTicketTotals,
    ticketCheckout,
    cloverCheckout,
    getCloverIframeToken,
  },
  selectors,
  reducer,
  name,
};
